import React from 'react';
import SfyHeader from "./SfyHeader"
import { Grid, TextField, Fab, Icon, Box, } from '@material-ui/core';

/// global data
import ServerData from "./ServerData";
import SendServiceData from "./SendServiceData";
import SessionData from "./SessionData";
import SettingsData from "./SettingsData";
import Format from "../Utilities/Format";

export default function SendServices(props) {
    const [ignore, triggerChange] = React.useState(false);
    // clear the data that wll be used to send services
    SessionData.sendingNote = false
    SendServiceData.URL = ""

    const onPersonallNoteChange = event => {
        let text = event.target.value
        //console.log('personalNoteText: ', text)
        SendServiceData.personalNoteText = text
        //SettingsData.CacheLocally = ('personalNoteText', text)
        window.localStorage.setItem('personalNoteText', text)
        SendServiceData.canSendServices = validSendTarget()
        triggerChange(!ignore)
    }

    const validSendTarget = () => {
        if (Format.validEmailAddress(SendServiceData.sendServicesTargetText)) {
            //console.log("valid email")
            return true
        }
        else if (Format.validPhoneNumber(SendServiceData.sendServicesTargetText) &&
            !SettingsData.inDemoMode) {
            //console.log("valid phone number")
            return true
        }
        else {
            //console.log("no valid send to target")
            return false
        }
    }
    const onSendTargetChange = event => {
        SendServiceData.sendServicesTargetText = event.target.value
        SendServiceData.canSendServices = validSendTarget()
        triggerChange(!ignore)
    }
    const generateHeaderBody = (timestamp) => {
        let optionsDate = {
            //weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        }
        let optionsTime = {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        }
        const dateFormatted = Intl.DateTimeFormat(navigator.language,optionsDate).format(timestamp)
        const timeFormatted = Intl.DateTimeFormat(navigator.language,optionsTime).format(timestamp)
        const dtString = dateFormatted + " " +timeFormatted
        var serviceCount = SendServiceData.servicesManifest.services.length
        var ret = "/" + (serviceCount+1) + "%0A" + dtString + "%0A%0A"
        console.log("SS.generateHeaderBody-> "+ ret)
        return ret
    }
    

    const sendServiceToYouth = () => {
        SendServiceData.servicesManifest.emailAddress = ""
        SendServiceData.servicesManifest.phoneNumber = ""
        // SendServiceData.servicesManifest is built in FindServices when opening SendServices
        if (Format.validEmailAddress(SendServiceData.sendServicesTargetText)) {
            SendServiceData.servicesManifest.emailAddress = SendServiceData.sendServicesTargetText
            //console.log("SSD.sM with email:" + SendServiceData.servicesManifest.emailAddress)
        }
        else if (Format.validPhoneNumber(SendServiceData.sendServicesTargetText)) {
            SendServiceData.servicesManifest.phoneNumber = Format.extractNumberString(SendServiceData.sendServicesTargetText)
            //console.log("SSD.sM with phone:" + SendServiceData.servicesManifest.phoneNumber)
        }
        else {
            alert("INTERNAL ERROR: There should always be a phone number or email address for sending services.")
            return
        }
        SendServiceData.servicesManifest.personalNote = SendServiceData.personalNoteText
        // build SendServiceData.URL here
        var timestamp = Date.now()

        SendServiceData.URL = (ServerData.getServerRootUrl() +
            "postNote?department=" + SettingsData.policeDepartment['agency'] +
            "&email=" + SettingsData.getActualEmailAddress() +
            "&noteType=services" +
            "&noteText=null" +
            "&isDemo=" + SettingsData.inDemoMode +
            "&headerBody=" + generateHeaderBody(timestamp)
        )
        SendServiceData.URL = SendServiceData.URL.replace(/ /g, '%20')
        //console.log ("SendServiceData.URL: "+SendServiceData.URL)
        // The URL is posted in FindServices.componentDidMount() 
        // the following SessionData.sendingNote flag enables posting
        SessionData.sendingNote = true

        //clear the SEND TO box
        SendServiceData.sendServicesTargetText = ""
        updateServiceScreen()

        setScreen('find-services')
    }

    const updateServiceScreen = () => {
        triggerChange(!ignore)
    }

    //console.log("SS--inDemoMode " + SettingsData.inDemoMode)
    SendServiceData.updateServiceScreen = updateServiceScreen
    var setScreen = props.setScreen
    var servicesTitle = SessionData.selectedServices.length + " RECOMMENDED SERVICE" + ((1 < SessionData.selectedServices.length) ? "S" : "")
    var sendToTitle = SettingsData.inDemoMode ? "SEND DEMO EMAIL TO:" : "SEND TO:"
    var sendToPlaceholder = SettingsData.inDemoMode ? "Enter Email" : "Enter Text Phone Number or Email"
    return (
        <div>
            <SfyHeader context='SFY' screenId={'send-services'} lastScreenId={props.lastScreenId} setScreen={setScreen} Settings={true} />
            <Box ml={3} mr={3}>
                <Grid container direction='column' >

                    <Box mb={-2}>
                        <p style={{ color: '#367BBD' }}> {servicesTitle} </p>
                    </Box>
                    <TextField
                        multiline={true}
                        id="generted-message"
                        color='primary'
                        fullWidth={true}
                        maxRows={12}
                        variant='outlined'
                        defaultValue={SendServiceData.generatedMuiText}
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <Box mb={-2} >
                        <p style={{ color: '#367BBD' }}>PERSONAL NOTE</p>
                    </Box>
                    <TextField
                        size='medium'
                        multiline={true}
                        id="personal-note-text"
                        placeholder='Optionally, enter your personal note to accompany the generated message.'
                        defaultValue={(0 < SendServiceData.personalNoteText.length) ? SendServiceData.personalNoteText : null}
                        color='primary'
                        fullWidth={true}
                        maxRows={2}
                        variant='outlined'
                        onChange={onPersonallNoteChange}
                    />

                    <Box mb={-2}>
                        <p style={{ color: '#367BBD' }}>{sendToTitle}</p>
                    </Box>
                    <Box mr={6}>
                        <Grid container direction='row'>
                            <TextField
                                id="phone-number"
                                placeholder={sendToPlaceholder}
                                defaultValue={(0 < SendServiceData.sendServicesTargetText.length) ? SendServiceData.sendServicesTargetText : null}
                                color='primary'
                                maxRows={1}
                                variant='outlined'
                                fullWidth={true}
                                onChange={onSendTargetChange}
                            />
                        </Grid>
                    </Box>
                    <Box ml={38} mt={-6}>
                        <Fab size='small' color='secondary' disabled={!SendServiceData.canSendServices} onClick={sendServiceToYouth}>
                            <Icon>send</Icon>
                        </Fab>
                    </Box>
                </Grid>
            </Box>
        </div>
    )
}