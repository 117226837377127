import React from 'react';

import SwitchScreen from "./components/SwitchScreen";

// Global Data
import RegistrationData from "./components/RegistrationData";
import SettingsData from "./components/SettingsData";
import SessionData from "./components/SessionData";
import ServerData from "./components/ServerData";
import SendServiceData from "./components/SendServiceData";
import PoliceDepartmentData from "./components/PoliceDepartmentData";

class App extends React.Component
{
  state = {
    loadingDepartments: true,
    departmentsLoaded: false,
    screen: 'register-screen'
  }

  constructor(props) {
    super()
    this.setScreen = this.setScreen.bind(this)
  }

  setScreen(screenId) {
    this.setState({screen: screenId})
    //console.log("App.setScreen " + this.state.screen)
  }

  async componentDidMount() {
    console.log(">> App.CDM <<")
    if (!SessionData.resetting) {
      console.log(">>>>>App.CDM--not resetting")
      SettingsData.restoreSessionFromLocal()
    }
    else {
        console.log(">>>>>App.CDM--resetting")
        this.setState({screen: 'badge-screen'})
        SendServiceData.phoneNumberText = ""
        SendServiceData.personalNoteText = ""
        SessionData.selectedServices = []
        SessionData.openCategories = []
        SessionData.resetting = false
    }
    //console.log("App.CDM--SettingsData.verified: " + SettingsData.verified)
    if (!SettingsData.verified) {
      // need to load the authorized police departments from the server 
      try {
        const url = (ServerData.getServerRootUrl()  + "getRegisteredDepartments").replace(/ /g,'%20')
        const response = await fetch(url)
        const data = await response.text()
        this.setState({loadingDepartments: false })
        if (!this.state.loadingDepartments) {
          let dataObject = (JSON.parse(data))[0]
          //console.log("DATA FOR DEPARTMENTS: "+ JSON.stringify(dataObject,null,3))
          PoliceDepartmentData.registeredDepartments = dataObject;
          //console.log("registeredDepartments "+  JSON.stringify(PoliceDepartmentData.registeredDepartments,null,3))
          this.setState({departmentsLoaded: true})
        }
      }
      catch (error) {
        console.log("App.CDM.PDs: "+error)
        alert("App.CDM.PDs: "+error)
      }
    }
    if (SettingsData.verified && !RegistrationData.loaded) {
      SettingsData.updateSessionKey = true
      this.setState({screen: 'session-error-screen'})
    }
    //console.log("SD.verified: "+SettingsData.verified+", RD.loaded: "+RegistrationData.loaded)
  }

  render () {
    //console.log("App.render() screen: "+ this.state.screen)
    return (
      <div>
        <SwitchScreen screen={this.state.screen} setScreen={this.setScreen} />
      </div>
    )
  }  
}

export default App