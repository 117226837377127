import ClientData from "../Services/ClientData";
import ServerData from "./ServerData";
import SettingsData from "./SettingsData";
import Utils from "../Utilities/Utils";

var request = require('request');

const TransmitOnline = {}

let postingCachedTransmissions = false
TransmitOnline.postCachedTransmissions = () => {
    if (postingCachedTransmissions) {
        return
    }
    else {
        if (0 === (getEvents().length + getDRs().length)) {
            //console.log("nothing to transmit.") 
            return
        }
        postingCachedTransmissions = true
        ClientData.log("TO",21,"events: "+getEvents().length+", DRs: "+getDRs().length+"...")
        if (0<getEvents().length) {
            postNextEvent()
        }
        else if (0<getDRs().length) {
            postNextDiversionRequest()
        }
        else {
            postingCachedTransmissions = false
        }
        // other transmission are
        // triggered by chaining 
        // from the callbacks
    }
}

TransmitOnline.reportEvent = (type,data) => {
    let timestamp = Date.now()
    let sessionId = SettingsData.sessionKey
    let email = SettingsData.getActualEmailAddress()
    let pd = SettingsData.policeDepartment
    let agencyNickname = Utils.getUniqueAgencyId(pd)
    let event = {"sessionId":sessionId,"email":email,"uniqueId":agencyNickname,"timestamp":timestamp,"type":type,"data":data}
    const eventUrl =  (ServerData.getServerRootUrl()  + 
    "postEvent?sessionId=" + event.sessionId +
    "&email=" +event.email +
    "&uniqueId=" +event.uniqueId +
    "&timestamp=" + event.timestamp + 
    "&eventType=" + event.type + 
    "&eventData=" + event.data ).replace(/ /g,'%20')
    ClientData.log("TO",51,"reportEvent--url: " + eventUrl)
    storeEvent(eventUrl)
}

const getEvents = () => {
    let eventStrings = window.localStorage.getItem('keptEvents')
    if ((null!==eventStrings) && (""!==eventStrings)) {
        var events = JSON.parse(eventStrings)
        //console.log("getEvents--count : "+events.length)
        return events
    }
    else  {
        return []
    }
}

const storeEvent = (eventUrl) => {
    let eventUrls = getEvents()
    eventUrls.push(eventUrl)
    window.localStorage.setItem('keptEvents', JSON.stringify(eventUrls))
}

const removeEvent = (eventUrl) => {
    let eventUrls = getEvents()
    let eventIndex = eventUrls.indexOf(eventUrl)
    ClientData.log("TO",76,"removeEvent: " + eventIndex + ", " + eventUrl)
    eventUrls.splice(eventIndex,1)
    window.localStorage.setItem('keptEvents', JSON.stringify(eventUrls))
}

const postNextEvent = () => {
    let events = getEvents()
    ClientData.log("TO",83,"postNextEvent " + events[0])
    postEvent(events[0])
}
const  postEvent = (url) => {
    ClientData.log("TO",87,"> postEvent " + url)
    var options = {
        url:  url,
        method: 'POST'
    }
    request(options,(error, response, body) => {
        postEventCallback(error, response, body, url)
    })
}

const postEventCallback = (error, response, body, eventUrl) => {
    ClientData.log("TO",98,">postEventCallback")
    let eventErrorMessage = ""
    if (undefined===response) {
        console.log("YSA is Offline.")
        postingCachedTransmissions = false
        ClientData.log("TO",103,"<postEventCallback")
        return
    }
    if (!error) {
        console.log("postEventCallback--response.status: " + response.statusCode);
        if (response.statusCode === 200) {
            removeEvent(eventUrl)
        }
        else if (response.statusCode === 412) {
            eventErrorMessage = "SYSTEM ERROR ("+body+")"
        }
        else {
            eventErrorMessage = "UNEXPECTED RESPONSE STATUS (" + response.statusCode + "): " + body
        }
    }
    else {
        ClientData.log("TO",119,"POST EVENT FAILED WITH ERROR: "+error+". Keeping event for later retry: " + eventUrl)
    }
    if (eventErrorMessage !== "") {
        ClientData.log("TO",122,"loadServiceDataCallback serverErrorMessage: " + eventErrorMessage)
        alert(eventErrorMessage)
    }
    if (0 < getEvents().length) {
        postNextEvent()
    }
    else {
        ClientData.log("TO",129,"No more events to post ")
        if (0 < getDRs().length) {
            postNextDiversionRequest()
        }
        else {
            ClientData.log("TO",134,"No more DRs to post.")
            postingCachedTransmissions = false
        }
    }
    ClientData.log("TO",138,"<postEventCallback")
}

TransmitOnline.reportDiversionRequest = (emailTarget,offenseDataJson, appUser,tempData) => {
    const url = (ServerData.getServerRootUrl()  + 
                    "postOffenseInstance?sessionKey=" + SettingsData.sessionKey +
                    "&demoUser=" + "true"+
                    "&emailTarget=" + emailTarget +
                    "&offenseDataJson=" + JSON.stringify(offenseDataJson) + 
                    "&appUser="+ appUser +
                    "&tempData="+ tempData ).replace(/ /g,'%20')
    // so the persistent and local untransmitted events are the same
    // in case the local process shuts down
    console.log("TOL.rDR) URL = "+ url)
    storeDR(url)
    ClientData.log("TO",153,"< reportDiversionRequest")
}

const removeDR = (url) => {
    let drs = getDRs()
    let indexDR = drs.indexOf(url)
    ClientData.log("TO",159,"removeEvent: " + indexDR + ", " + url)
    drs.splice(indexDR,1)
    window.localStorage.setItem('keptDRs', JSON.stringify(drs))
}

const getDRs = () => {
    let drStrings = window.localStorage.getItem('keptDRs')
    if ((null!==drStrings) && (""!==drStrings)) {
        var drs = JSON.parse(drStrings) 
        //console.log("getDRs--count: "+drs.length)
        return drs
    }
    else  {
        return []
    } 
}

const storeDR = (url) => {
    let drs = getDRs()
    ClientData.log("TO",178,"> storeDR " + drs.length)
    drs.push(url)
    window.localStorage.setItem('keptDRs', JSON.stringify(drs))
    let kDRs = getDRs()
    ClientData.log("TO",182,"< storeDR " + kDRs.length)
}

const postNextDiversionRequest = () => {
    let diversionRequests = getDRs()
    ClientData.log("TO",187,"postNextDiversionRequest " + diversionRequests[0])
    TransmitOnline.postDiversionRequest(diversionRequests[0])
}

TransmitOnline.postDiversionRequest = (url) => {
    ClientData.log("TO",192,"> postDiversionRequest" + url)
    var options = {
        url:  url,
        method: 'POST'
    }
    ClientData.log("TO",200,"postDiversionRequest--url: " + url.substring(0, 200))
    try {
        request(options,(error, response, body) => {
            postDiversionRequestCallback(error, response, body, url)
        })
    }
    catch (error2) {
        if (!ClientData.LogOn) {
            console.log("postDiversionRequest--error: "+error2)
        }
        ClientData.log("TO",210,"postDiversionRequest--error: "+error2)
    }
}

const postDiversionRequestCallback = (error, response, body, url) => {
    ClientData.log("TO",209,">postDiversionRequestCallback")
    let DiversionRequestErrorMessage = ""
    if (undefined===response) {
        console.log("YSA is Offline.")
        postingCachedTransmissions = false
        ClientData.log("TO",214,"<postDiversionRequestCallback")
        return
    }
    //console.log("postDiversionRequestCallback--status:"+ response.statusCode)
    ClientData.log("TO",218,"postDiversionRequestCallback--status:"+ response.statusCode + "\neERROR: " + error + "\nBODY: " + body + "\nRESPONSE: " + JSON.stringify(response,null,3))
    if (!error) {
        if (response.statusCode === 200) {
            removeDR(url) 
        }
        else if (response.statusCode === 412) {
            DiversionRequestErrorMessage = "SYSTEM ERROR ("+body+")"
        }
        else if (response.statusCode === 202) {
            removeDR(url)  
            ClientData.log("TO",228,"Unexpected 202: "+response.statusCode+" ERROR: "+error)
        }
        else {
            DiversionRequestErrorMessage = "UNEXPECTED RESPONSE STATUS (" + response.statusCode + "): " + body
        }
     }
    else {
        ClientData.log("TO",235,"POST DIVERTABLE OFFENSE FAILED WITH STATUS: "+response.statusCode+" ERROR: "+error+". Storing URL: " + url.substring(0,200))
    }
    if (DiversionRequestErrorMessage !== "") {
        ClientData.log("TO",238,"postDiversionRequestCallback serverErrorMessage: " + DiversionRequestErrorMessage)
        alert(DiversionRequestErrorMessage)
    }
    if (0 < getDRs().length) {
        postNextDiversionRequest()
    }
    else {
        ClientData.log("TO",245,"No more DRs to post.")
        postingCachedTransmissions = false
    }
    ClientData.log("TO",248,"<postDiversionRequestCallback")
}

export default TransmitOnline