const SendServiceData = {}
SendServiceData.canSendServices = false
SendServiceData.personalNoteText = ''
SendServiceData.generatedMuiText = ''
SendServiceData.generatedEmailText = ''
SendServiceData.sendServicesTargetText = ''
SendServiceData.updateServiceScreen = {}
SendServiceData.servicesManifest    = {}  // raw services information object in a form that can generate an email or a text
SendServiceData.URL = ''


SendServiceData.clearAll = () => {
    SendServiceData.canSendServices = false
    SendServiceData.personalNoteText = ''
    SendServiceData.generatedMuiText = ''
    SendServiceData.generatedEmailText = ''
    SendServiceData.sendServicesTargetText = ''
    SendServiceData.updateServiceScreen = {}
    SendServiceData.servicesManifest    = {}
}

export default SendServiceData